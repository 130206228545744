@font-face {
  font-family: "Contorno";
  src: local("Contorno"), url(../public/GLOUCEST.TTF) format("opentype");
}
html {
  background: rgb(9, 6, 56);
  color: rgb(226, 223, 223) !important;
  font-size: 25px;
}
.MuiTextField-root {
  color: white !important;
}
.titulo {
  text-align: center;
}
label {
  font-size: 14px;
  margin-top: 10px;
}
.uno {
  color: rgb(185, 199, 57);
}
.dos {
  color: rgb(9, 6, 56);
  text-shadow: -1px -1px 0 rgb(185, 199, 57), 1px -1px 0 rgb(185, 199, 57),
    -1px 2px 0 rgb(185, 199, 57), 1px 1px 0 rgb(185, 199, 57);
}

.tres {
  margin: 0 auto;
  width: 83%;
  align-items: center;
  margin-top: 10px;
  align-content: center;
  text-align: center;

  background-color: rgb(18, 41, 104);
}
.text-amarillo {
  color: rgb(185, 199, 57);
  text-align: center;
  font-size: 14px;
}
.boton {
  width: 85;
}
.selects {
  width: 100%;
  height: 38px;
  background: transparent;
  color: rgb(119, 118, 118);
  border: 1.8px white solid;
  border-radius: 5px;
  font-size: 15px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: white !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}
.iframe {
  align-items: center;
  text-align: center;
  margin: 0 auto;
  display: block;
}
#custom-css-outlined-input-label {
  color: white !important;
}
.video {
  position: relative;
}
.marca {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-family: Arial;
  font-weight: bold;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
}
